/* eslint-disable */
if (!localStorage.getItem('search-and-bookmark-data')) {
  localStorage.setItem('search-and-bookmark-data', JSON.stringify({
    pages: {
      key: 'title',
      data: [
                {
          title: 'Пользователи',
                    route: 'users',
                              icon: 'UserIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Проекты',
                    route: 'projects',
                              icon: 'BriefcaseIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Отчеты',
                    route: 'reports',
                              icon: 'PieChartIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Платежи',
                    route: 'payments',
                              icon: 'CreditCardIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Документы',
                    route: 'docs',
                              icon: 'FileIcon',
                              isBookmarked: '',
                  },
              ]
    },
  }))
}
// prettier-ignore
export default [
    {
    title: 'Пользователи',
        route: 'users',
            icon: 'UserIcon',
          },
    {
    title: 'Проекты',
        route: 'projects',
            icon: 'BriefcaseIcon',
          },
    {
    title: 'Отчеты',
        route: 'reports',
            icon: 'PieChartIcon',
          },
    {
    title: 'Платежи',
        route: 'payments',
            icon: 'CreditCardIcon',
          },
    {
    title: 'Документы',
        route: 'docs',
            icon: 'FileIcon',
          },
  ]
